import React, { ReactElement } from 'react'
import { OnBoardingTemplate } from '../../../types/templates'
import { InstallKeyboardIcon, List } from '../../elements'
import { Alert } from '../../grouping'
import OnBoarding from '../../templates/onboarding'

const pageTitle: OnBoardingTemplate['pageTitle'] = {
  subTitle: 'On Boarding 4/4',
  title: 'Install additional language keyboards',
}

export default function InstallKeyboards(): ReactElement {
  return (
    <OnBoarding icon={<InstallKeyboardIcon />} pageTitle={pageTitle}>
      <List className="u__mc--vertical" type="ol" fancy>
        <li>Open the Settings app on this device and go to the General section.</li>
        <li>Select the Keyboard option and tap the Keyboards button.</li>
        <li>
          Tap the Add New Keyboard option. Browse through the list of available keyboards and tap the language you want
          to add.
        </li>
      </List>
      <Alert>
        <p>
          If you want to reorder the list or remove a language from the list, tap the Edit button in the top-right
          corner. To change between the keyboards, tap and hold the Globe icon in the bottom-left corner of the screen
          to change between the languages.
        </p>
      </Alert>
    </OnBoarding>
  )
}
