import { useEffect, useState } from 'react'
import IsBrowser from '../utils/is-browser'

export default function useIsPWA() {
  const [isPWA, setIsPWA] = useState(false)

  useEffect(() => {
    if (IsBrowser()) {
      const mqStandAlone = '(display-mode: standalone)'

      if (window.matchMedia(mqStandAlone).matches) {
        setIsPWA(true)
      }
    }
  }, [])

  return isPWA
}
