import React, { ReactElement } from 'react'
import { OnBoardingTemplate } from '../../../types/templates'
import { InstallAppIcon, List } from '../../elements'
import OnBoarding from '../../templates/onboarding'
import ShareIcon from '../../../images/icon-share.svg'
import { Alert } from '../../grouping'
import { useAppDispatch } from '../../../state/app-context'
import useIsPWA from '../../../hooks/use-is-pwa'
import { VIDEO_PATH } from '../../../types/constants'
import { AppStateDispatchType } from '../../../types/enums'
import isOnline from '../../../utils/get-online-status'
import Accordion from '../../components/accordion'

const pageTitle: OnBoardingTemplate['pageTitle'] = {
  subTitle: 'On Boarding 1/4',
  title: 'Install the application',
}

export default function InstallApp(): ReactElement {
  const dispatch = useAppDispatch()
  const isPWA = useIsPWA()

  const onClick = () => {
    dispatch({
      type: AppStateDispatchType.updateVideoDownloaded,
      payload: true,
    })
  }

  return (
    <OnBoarding icon={<InstallAppIcon />} pageTitle={pageTitle}>
      {isOnline && (
        <Alert>
          <p>
            Please download the{' '}
            <a onClick={onClick} href={VIDEO_PATH} download target="_blank" rel="noopener noreferrer">
              How to apply the label
            </a>{' '}
            video to make it available for offline viewing.
          </p>
          <Accordion title="Where will the video be stored?" className="c__accordion--compact">
            <p>
              The video will be stored depending on your iPad settings. Go to &apos;Settings {'>'} Safari {'>'}{' '}
              Downloads&apos; to see the location. We recommend setting it to &apos;On My iPad&apos;. This will make the
              video available in the <strong>Files</strong> app.
            </p>
          </Accordion>
        </Alert>
      )}

      <List className="u__mc--vertical" type="ol" fancy>
        <li>
          Click on the &quot;Share&quot; button{' '}
          <ShareIcon style={{ display: 'inline-block', width: 32, height: 32, verticalAlign: 'middle' }} />.
        </li>
        <li>
          Select &quot;Add to Home Screen&quot;, you may need to scroll in the popup menu to see this option. Then
          select &quot;Add&quot;.
        </li>
        <li>Close Safari, you should now see the app icon appear on your Home Screen.</li>
        {/* Need to load and cache this font before the user installs... */}
        <li className="sr-only" style={{ fontFamily: 'DIN-Condensed' }}>
          dont judge me
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          {/* <video autoPlay controls crossOrigin="anonymous" muted src={VIDEO_PATH} /> */}
        </li>
      </List>

      {!isPWA && (
        <Alert>
          <p>
            Once the app is downloaded, launch the app and wait for <i>&quot;The app is ready for offline use&quot;</i>{' '}
            message to appear before switching wifi connections. It may take a up to 10 seconds depending on your
            internet connection.
          </p>
        </Alert>
      )}
    </OnBoarding>
  )
}
