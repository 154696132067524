import React, { ChangeEvent, FormEvent, MouseEvent, useMemo, useState } from 'react'
import useCensoredWords from '../../../hooks/use-censored-words'
import useFocused from '../../../hooks/use-focused'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useEditorState } from '../../../state/editor-context'
import { useAppState } from '../../../state/app-context'
import IsBrowser from '../../../utils/is-browser'
import { Button, Control, InputField, LoadingSVG, MaxLengthIndicator } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'

type Properties = {
  onSubmit: (mainHeading: string) => Promise<void>
  processing: boolean
}

export default function TestPrintForm({ onSubmit, processing }: Properties) {
  const { isCensoredWordsAllowed } = useEditorState()
  const { appLanguage } = useAppState()
  const [value, setValue] = useState('')
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const becameEmpty = useRequiredBecameEmpty(value)
  const { cleanedWord, containsBadWord, wordForLabel } = useCensoredWords(value, appLanguage, isCensoredWordsAllowed)
  const [focused, onFocus, onBlur] = useFocused()

  const fieldError = useMemo(() => becameEmpty || containsBadWord || (submitAttempted && value === ''), [
    becameEmpty,
    containsBadWord,
    submitAttempted,
    value,
  ])

  const onTestPrint = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setSubmitAttempted(true)

    if (value.trim()) {
      ;(document.activeElement as HTMLElement).blur()
      setTimeout(() => {
        onSubmit(wordForLabel)
      }, 100)
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (IsBrowser()) {
      ;(document.activeElement as HTMLElement).blur()
    }
  }

  return (
    <>
      <form className="c__text-print-form" onSubmit={onFormSubmit}>
        <div className="c__test-print align-end">
          <InputField className="c__text-print-form__input-field" error={fieldError} hasMaxLength>
            <Control
              label={{ htmlFor: 'label', children: 'Test label' }}
              input={{
                name: 'label',
                value: focused ? value : cleanedWord,
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  setValue(event.target.value)
                },
                maxLength: 35,
                autoComplete: 'off',
                onFocus,
                onBlur,
              }}
            />
            <MaxLengthIndicator currentValue={value} maxLength={35} />
          </InputField>
          <Button
            type="button"
            disabled={fieldError || processing}
            variant={!value ? 'default' : 'secondary'}
            className={processing ? 'loading' : ''}
            onClick={onTestPrint}
          >
            {processing ? <LoadingSVG /> : 'Test print'}
          </Button>
        </div>
        {becameEmpty && (
          <InputErrorMessage>*This section is mandatory, please enter in the heading for the label</InputErrorMessage>
        )}
        {!becameEmpty && containsBadWord && (
          <InputErrorMessage>Inappropriate message choice. Please choose something else. </InputErrorMessage>
        )}
      </form>
    </>
  )
}
