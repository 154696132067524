import React, { ReactElement } from 'react'
import { useAppState } from '../../../state/app-context'
import { OnBoardingTemplate } from '../../../types/templates'
import TestPrintForm from '../../components/test-print-form'
import { List, TestPrintIcon } from '../../elements'
import { Alert } from '../../grouping'
import OnBoarding from '../../templates/onboarding'

const pageTitle: OnBoardingTemplate['pageTitle'] = {
  subTitle: 'On Boarding 3/4',
  title: 'Test print before you start',
}

type Properties = {
  onSubmit: (mainHeading: string) => Promise<void>
  processing: boolean
}

export default function TestPrint({ onSubmit, processing }: Properties): ReactElement {
  const { hasCompletedTestPrint } = useAppState()

  return (
    <>
      <OnBoarding icon={<TestPrintIcon />} pageTitle={pageTitle} className="u__hide-on-print">
        <List className="u__mc--vertical" type="ol" fancy>
          <li>Put an A6 piece of paper into the printer tray.</li>
          <li>Enter in a test label message in the input field below and press print when it is ready.</li>
          <li>
            Double check the label is printed out correctly. If you have any issues, go back to the beginning and check
            you have followed all the steps correctly.
          </li>
        </List>
        <TestPrintForm processing={processing} onSubmit={onSubmit} />

        {!hasCompletedTestPrint && (
          <Alert>
            <p>Please complete a test print before continuing with the onboarding</p>
          </Alert>
        )}
      </OnBoarding>
    </>
  )
}
