import React, { ReactElement } from 'react'
import { OnBoardingTemplate } from '../../../types/templates'
import { ConnectPrinterIcon, List } from '../../elements'
import { Alert } from '../../grouping'
import OnBoarding from '../../templates/onboarding'

const pageTitle: OnBoardingTemplate['pageTitle'] = {
  subTitle: 'On Boarding 2/4',
  title: 'Connect device to printer',
}

export default function ConnectPrinter(): ReactElement {
  return (
    <OnBoarding icon={<ConnectPrinterIcon />} pageTitle={pageTitle}>
      <List className="u__mc--vertical" type="ol" fancy>
        <li>Make sure this device and the label printer are on the same WiFi network.</li>
        <li>
          Open the Settings app on this device and then go to WiFi. Select the network that the printer is connected to
          and input any password that is required.
        </li>
        <li>Once on the same network, you’ll be automatically connected and ready to print.</li>
      </List>
      <Alert>
        <p>
          If your device is on 3G or 4G, you’ll need to connect the printer to your device. Turn on the Personal Hotspot
          in settings on this device and conenct to the network via the printer.
        </p>
      </Alert>
    </OnBoarding>
  )
}
