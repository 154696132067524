import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { TDetails } from 'keen-slider'
import PageLayout from '../components/components/page-layout'
import { AnchorButton, ArrowRightIcon, Section } from '../components/elements'
import ConnectPrinter from '../components/screens/onboarding/connect-printer'
import InstallKeyboards from '../components/screens/onboarding/install-keyboards'
import TestPrint from '../components/screens/onboarding/test-print'
import { Page } from '../types/page'
import Slider from '../components/components/slider'
import { DEFAULT_BUTTON_CLASS } from '../components/elements/_config'
import PrintableSpace from '../components/components/printable-space'
import InstallApp from '../components/screens/onboarding/install-app'
import useIsPWA from '../hooks/use-is-pwa'
import { useAppDispatch, useAppState } from '../state/app-context'
import { AppStateDispatchType } from '../types/enums'
import printLabel from '../lib/print-label'
import IsBrowser from '../utils/is-browser'

export default function OnboardingTemplate({
  pageContext: { language },
}: Page.TemplateOnboardingProperties): ReactElement {
  const { firstTimeUser, hasCompletedTestPrint } = useAppState()
  const dispatch = useAppDispatch()
  const isPWA = useIsPWA()
  const [isLast, setIsLast] = useState(false)
  const [heading, setHeading] = useState('')
  const [processing, setProcessing] = useState(false)
  const printableReference = useRef<HTMLDivElement>()

  const skipAllowed = useMemo(() => {
    return !firstTimeUser || hasCompletedTestPrint
  }, [firstTimeUser, hasCompletedTestPrint])

  const allowSwiftExit = useMemo(() => skipAllowed || (skipAllowed && isLast), [isLast, skipAllowed])

  const onSlideChange = (details: TDetails) => {
    setIsLast(details.relativeSlide + 1 === details.size)
  }

  const onSubmit = async (mainHeading: string) => {
    setProcessing(true)
    setHeading(mainHeading)

    if (IsBrowser() && printableReference.current) {
      setTimeout(async () => {
        await printLabel.execute(printableReference.current)
        setProcessing(false)
        dispatch({
          type: AppStateDispatchType.updateCompletedTestPrint,
          payload: true,
        })
      }, 100)
    }
  }

  return (
    <PageLayout lang={language} className={allowSwiftExit ? '' : 'c__header--hide-hamburger'}>
      <PrintableSpace ref={printableReference} id="aged-18-years" className="aged-18-years" mainHeading={heading} />

      <Section className="c__onboarding-template u__hide-on-print">
        <Slider includeArrows includeDots onSlideChange={onSlideChange} initial={isPWA ? 1 : 0}>
          <InstallApp />
          <ConnectPrinter />
          <TestPrint processing={processing} onSubmit={onSubmit} />
          {/* eslint-disable-next-line prettier/prettier */}
          {hasCompletedTestPrint && <InstallKeyboards />}
        </Slider>

        <div className="flex justify-end c__onboarding-template__page-action u__hide-on-print">
          {allowSwiftExit && (
            <AnchorButton
              href={`/${language}/setup`}
              variant="link"
              className={`${DEFAULT_BUTTON_CLASS}--icon ${DEFAULT_BUTTON_CLASS}--icon--right`}
            >
              {isLast ? 'Settings' : 'Skip all'} <ArrowRightIcon />
            </AnchorButton>
          )}
        </div>
      </Section>
    </PageLayout>
  )
}
